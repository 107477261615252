<template>
  <div
    :class="{
      overlay: props.open,
    }"
    @click="props.onClose"
  >
    <transition name="slide">
      <AppBaseColumn
        v-if="props.open"
        gap="zero"
        width=""
        justify="start"
        class="fixed right-0 top-0 h-full w-full lg:w-[400px]"
        @click.stop
      >
        <AppBaseRow gap="xsmall" items="center" containerType="core">
          <span
            class="icon-arrow-left text-lg cursor-pointer mr-3"
            :class="props.backVisible ? 'block' : 'hidden'"
            @click="handleBack"
          />
          <span class="s2 d w-full text-left">
            {{ title }}
          </span>
          <span
            class="icon-remove text-lg cursor-pointer"
            @click="props.onClose"
          />
        </AppBaseRow>
        <AppBaseRow
          border="border-y border-surface-60 overflow-y-auto"
          :containerType="hasPadding ? 'core' : 'base'"
        >
          <slot name="content" />
        </AppBaseRow>
        <AppBaseRow containerType="core" justify="end" gap="xsmall">
          <slot name="footer-secondary-button" />
          <slot name="footer-primary-button" />
        </AppBaseRow>
      </AppBaseColumn>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
  onBack: {
    type: Function,
    required: false,
  },
  backVisible: {
    type: Boolean,
    required: false,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  hasPadding: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const handleBack = () => {
  if (props.onBack) {
    props.onBack();
  }
};
</script>

<style scoped>
.overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 z-50;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}
</style>
